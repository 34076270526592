<template>
  <div>
    <h2 v-html="$t('search_milk_quality_type_probe_art')"></h2>
    <!-- Tierart -->
    <v-select
      id="search_milk_quality_type_animal_type"
      v-model="SearchMilkQualityTypeStore.Quality_animalTypeId"
      :items="animalTypes"
      :item-title="this.$getLangKey()"
      :label="$t('search_milk_quality_type_animal_type')"
      clearable
      @keyup.enter="search"
      item-value="id"
    />
    <!-- ProbeTyp -->
    <v-select
      id="search_milk_quality_type_sample_type"
      v-model="SearchMilkQualityTypeStore.Quality_sampleTypeId"
      :items="sampleTypes"
      :label="$t('search_milk_quality_type_sample_type')"
      clearable
      @keyup.enter="search"
    />
    <!-- Probenstatus -->
    <v-select
      id="search_milk_quality_type_sample_status"
      v-model="SearchMilkQualityTypeStore.Quality_sampleStatus"
      :label="$t('search_milk_quality_type_sample_status')"
      :items="sampleStatuses"
      :item-title="this.$getLangKey()"
      item-value="name"
      clearable
      @keyup.enter="search"
    />
    <!-- expanded-->
    <div class="link align-right" @click="expanded = !expanded">
      <span v-if="!expanded" v-html="$t('more')"></span>
      <span v-else v-html="$t('less')"></span>
    </div>
    <div v-if="expanded">
      <!-- Fehlercode -->
      <v-select
        id="search_milk_quality_type_error_code"
        v-model="SearchMilkQualityTypeStore.Quality_errorCodeLab"
        :items="errorCodes"
        :item-title="this.$getLangKey()"
        item-value="name"
        :label="$t('search_milk_quality_type_error_code')"
        clearable
        @keyup.enter="search"
      />
      <!-- Verwerter (MBH) -->
      <v-text-field
        id="search_milk_quality_type_mbh_processor"
        v-model="SearchMilkQualityTypeStore.Quality_dairyIdent"
        :label="$t('search_milk_quality_type_mbh_processor')"
        type="number"
        @keyup.enter="search"
      />
      <!-- Referenz-Labor -->
      <v-text-field
        id="search_milk_quality_type_reference_labor"
        v-model="SearchMilkQualityTypeStore.Quality_primaryKeyLab"
        :label="$t('search_milk_quality_type_reference_labor')"
        type="text"
        @keyup.enter="search"
      />
      <!-- InfoFeld -->
      <div v-if="$privileges.has({ path: '/milkQualities', permission: 'allDomains' })">
        <v-select
          :label="$t('search_milk_quality_type_info')"
          :items="info"
          v-model="SearchMilkQualityTypeStore.Quality_hasInfo"
          item-value="value"
          item-title="text"
          clearable
          @keyup.enter="search"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import baseSearchVue from './baseSearch.vue'
import { app } from '../../main'

import { useAnimalTypesStore } from '@/store/enums/AnimalTypesStore'
import { useErrorCodesStore } from '@/store/enums/ErrorCodesStore'
import { useSampleStatusStore } from '@/store/enums/SampleStatusStore'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'searchMilkQualityType',
  extends: baseSearchVue,
  data() {
    return {
      expanded: false,
      app: app,
      sampleTypes: [
        { value: 1, text: this.$t('search_milk_quality_type_quality') },
        { value: 2, text: this.$t('search_milk_quality_type_content') },
        {
          value: 3,
          text: this.$t('search_milk_quality_type_collecting_point')
        },
        { value: 4, text: this.$t('search_milk_quality_type_month_value') }
      ],
      info: [
        { text: this.$t('search_milk_quality_type_info_yes'), value: true },
        { text: this.$t('search_milk_quality_type_info_no'), value: false }
      ]
    }
  },
  computed: {
    animalTypes() {
      return useAnimalTypesStore().AnimalTypes
    },
    errorCodes() {
      return useErrorCodesStore().errorCodes
    },
    sampleStatuses() {
      return useSampleStatusStore().sampleStatuses
    },
    SearchMilkQualityTypeStore() {
      return this.$store.state.SearchMilkQualityTypeStore
    }
  },
  methods: {
    reset() {
      this.resetFields(this.SearchMilkQualityTypeStore)
    }
  },
  beforeMount() {
    useAnimalTypesStore().fetchAnimalTypes()
    useErrorCodesStore().fetchErrorCodes()
    useSampleStatusStore().fetchSampleStatuses()
    this.initSearchStoreFromQuery(this.SearchMilkQualityTypeStore, this.$route.query)
  }
})
</script>
