//import { axios } from 'vue/types/umd';
import { apiURL } from '@/main'
import { app } from '@/main'
import { showError } from '@/services/axios'
import { defineStore } from 'pinia'
export const useErrorCodesStore = defineStore('errorCodesStore', {
  state: () => ({
    errorCodes: [] as any[]
  }),
  actions: {
    async fetchErrorCodes() {
      if (this.errorCodes.length == 0) {
        try {
          const result = await app.axios.get(apiURL + '/errorCodes')
          this.errorCodes = result.data
        } catch (e) {
          showError(e)
        }
      }
    }
  }
})
